66<template>
  <div v-loading="loading" class="table-area history-area">
    <div  class="table-top">
    <el-row>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading heading-active">Active Positions</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'admin' || getRoleSlug == 'broker'" class="search-wrapper">
          
          <div class="search-wrapper">
            <el-input
              placeholder="Search"
              v-model="listQuery.search"
              class="input-with-select"
              style="width:500px;"
            >
              <el-select
                slot="prepend"
                v-model="listQuery.search_type_client"
                placeholder="select"
                clearable
                filterable
                style="width: 150px"
                @change="handleSelectSearchType()"
              >
                <el-option
                  v-for="item in listDataDropdownClients"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch()"
              ></el-button>
            </el-input>
          </div>



        </div>
        <div v-else-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            style="width:500px;"
          >
            <el-select
              slot="prepend"
              v-model="listQuery.search_type"
              placeholder="select"
              clearable
              filterable
              style="width: 150px"
              @change="handleSelectSearchType()"
            >
              <el-option
                v-for="item in listAdmins"
                :key="item.master"
                :label="item.name"
                :value="item.master"
              />
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="handleSearch()"
            ></el-button>
          </el-input>
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <el-button type="primary" v-if="getRoleSlug == 'admin' && permissions.allow_to_trade_for_client" @click="handleCreate()">Add</el-button>
        </div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="activePositions"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionShowVisibility="false"
      :tableActionVisibility="false"
      :tableActionViewDetailsVisibility="true"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="false"
      :tableLoadingStatus.sync="loading"
      @pagination="handlePagination()"
      @view="handleViewDetails($event)"
    />
    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :listDataDropdownClients="listDataDropdownClients"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/active-positions/components/AddEditDialog.vue";
import { fetchList } from "@/api/active-position";
import getRoleSlug from '@/store/app.js'
import { store } from "@/api/market-watch";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { fetchClientList } from "@/api/market-watch";



export default {
  name: "ListActivePositions",
  components: {
    Table,
    AddEditDialog,
  },
  data() {
    return {
      loading: false,
      listDataDropdownClients: [],
      dialogVisible: false,
      dialogType: "create",
      formData: {},
      formErrors: [],
      config: [
        {
          label: "Script",
          prop: "script",
          width: "300",
          popover: true,
          className: "redFont",
        },
        {
          label: "Active Buy",
          prop: "buy_unit_and_lot",
          width: "150",
          className: "redFont",
        },
        {
          label: "Active Sell",
          prop: "sell_unit_and_lot",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Avg buy rate",
          prop: "avg_buy",
          width: "",
          className: "blueFont",
        },
        {
          label: "Avg sell rate",
          prop: "avg_sell",
          width: "",
          className: "blueFont",
        },
        {
          label: "Total",
          prop: "total",
          width: "",
          className: "blueFont",
        },
        {
          label: "Net",
          prop: "net",
          width: "",
          className: "blueFont",
        },
      ],
      listData: {},
      listAdmins: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        search_type_client:"",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      data: {

      },
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "permissions" ]),
  },
  created() {
    this.getList();
    if(getRoleSlug.state.roleSlug == 'superadmin'){
      this.getAdminList();
    }else if(getRoleSlug.state.roleSlug == 'admin' || getRoleSlug.state.roleSlug == 'broker'){
      this.getClientList();
    }
    
  },
  methods: {
      handleViewDetails(data) {
        if(this.listQuery.search_type_client){
          return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/trading-clients/trading-clients-view/" + this.listQuery.search_type_client);
        }else{
          return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/active-trade-positions/" + data.stock_id);
        }
      
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      if (data.dialog_type === "edit") {
        this.handleUpdate(data);
      } else {
        this.handleStore(data);
      }
    },
    handleSelectSearchType() {
      this.handleSearch()
    },
    // //////////////////
    // CREATE
    // //////////////////
    handleCreate() {
      this.formData = {
        // segment: "",
        // trade: "",
        // condition: "",
      };
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleSearch() {
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    // //////////////////
    // active order submit 
    // //////////////////
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      store(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listData = response.data;
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    getClientList() {
      //this.loading = true;
      fetchClientList().then((response) => {
        console.log("point 2 ")
        this.listDataDropdownClients = response.data.data;
      });
    },
  },
};
</script>
